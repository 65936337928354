import React from 'react';
import './Contact.css';

const Contact = ({}) => {


  

  return (
      <div className="contact-container">
        Will be updated soon

       
      </div>
  );
};

export default Contact;
